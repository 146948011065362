import { application } from 'controllers/application';

const useMobileApp = (controller) => {
  controller.mobileController = application
    .getControllerForElementAndIdentifier(
      document.body,
      'mobile-app',
    );

  Object.assign(controller, {
    isMobileApp: () => {
      return controller.mobileController.platformValue !== 'browser';
    },
  });
};

export default useMobileApp;
