import { Application } from '@hotwired/stimulus';
import consumer from 'channels/consumer';

const application = Application.start();

// Configure Stimulus development experience
// application.debug = import.meta.env.RAILS_ENV !== 'production';
application.debug = false;
application.consumer = consumer;

window.Stimulus = application;
console.log(import.meta.env.RAILS_ENV)
export { application };
