import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    platform: String,
  }

  signInWithGoogle() {
    if (this.#isAndroid()) {
      window.Android.signInWithGoogle();
    }

    if (this.#isIos()) {
      window.webkit.messageHandlers.signInWithGoogle.postMessage(null);
    }
  }

  share(url) {
    if (this.#isAndroid()) {
      return window.Android.share(url);
    }

    if (this.#isIos()) {
      window.webkit.messageHandlers.share.postMessage(url);
    }
  }

  launchCamera() {
    if (this.#isAndroid()) {
      return window.Android.launchCamera();
    }

    if (this.#isIos()) {
      window.webkit.messageHandlers.launchCamera.postMessage(null);
    }
  }

  openInBrowser(url) {
    if (this.#isAndroid()) {
      window.Android.openInBrowser(url);
    }

    if (this.#isIos()) {
      window.webkit.messageHandlers.openInBrowser.postMessage(url);
    }
  }

  // Private methods

  #isAndroid() {
    return this.platformValue === 'android' && this.#checkAndroidInstanceExists();
  }

  #isIos() {
    return this.platformValue === 'ios' && this.#checkIosInstanceExists();
  }

  #checkIosInstanceExists() {
    if (window.webkit) return true;

    alert('The window.webkit instance is not available. Please make sure you are using the mobile app to access this feature.');
    return false;
  }

  #checkAndroidInstanceExists() {
    if (window.Android) return true;

    alert('The window.Android instance is not available. Please make sure you are using the mobile app to access this feature.');
    return false;
  }
}
