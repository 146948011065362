import { Controller } from '@hotwired/stimulus';
import useMobileApp from 'mixins/use_mobile_app'; 

export default class extends Controller {
  static values = {
    actionName: {
      type: String,
      default: null,
    },
    actionArgs: {
      type: Array,
      default: [],
    },
  }

  connect() {
    useMobileApp(this);
  }

  call() {
    if (typeof this.mobileController[this.actionNameValue] !== 'function') {
      console.error('The action name provided is not a function on the mobile controller');
      return;
    }

    if (this.actionArgsValue.length > 0) {
      this.mobileController[this.actionNameValue](...this.actionArgsValue);
    } else {
      this.mobileController[this.actionNameValue](this.actionArgsValue);
    }
  }
}
